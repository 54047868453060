<template>
  <div class="two-factor-email">
    <div
      ref="enterEmail"
      :is="isModal ? 'Modal' : 'div'"
      v-show="isModal ? false : state === states.enter_email"
      v-bind="isModal
        ? {
          name: 'two-factor-email-enter',
          active: state === states.enter_email,
          title: $t('searchByEmail/title'),
          isDefault: true,
        }
        : null"
      @afterClose="state = null"
    >
      <Alert
        class="two-factor-email__form-alert"
        state="warning"
        :title="$t('searchByEmail/alertTitle')"
        :text="$t('searchByEmail/alertText')"
        icon="lock"
        noAction
      ></Alert>
      <form
        class="two-factor-email__form"
        @submit.prevent="sendCode"
        novalidate
      >
        <Field
          class="two-factor-email__form-field"
          :label="$t('searchByEmail/label')"
          labelSize="sm"
          labelFor="email"
        >
          <Input
            class="two-factor-email__form-input"
            v-model.trim="form.email"
            id="email"
            type="email"
            placeholder="your@email.com"
            :state="(isDirty || emailServerError) && emailErrorType ? 'error' : null"
            wide
          ></Input>
          <Button
            class="two-factor-email__form-button"
            variant="primary"
            type="submit"
            :text="$t('searchByEmail/sendButton')"
            :disabled="isDirty && !isValid"
            :loading="isLoading"
            mobile
          ></Button>

          <template v-slot:msg>
            <div
              v-if="(isDirty || emailServerError) && emailErrorMessages[emailErrorType]"
              class="two-factor-email__form-error"
            >
              {{ emailErrorMessages[emailErrorType] }}
            </div>
          </template>
        </Field>
      </form>
    </div>

    <div
      ref="checkEmail"
      :is="isModal ? 'Modal' : 'div'"
      v-show="isModal ? false : state === states.check_email"
      v-bind="isModal
        ? {
          name: 'two-factor-email-check',
          active: state === states.check_email,
          title: $t('searchByEmail/checkTitle'),
          subtitle: `${$t('searchByEmail/checkSubtitle')}: <b>${form.email}</b>`,
          isDefault: true,
        }
        : null"
      @afterClose="state = null"
    >
      <header
        class="two-factor-email__heading"
        v-if="!isModal"
      >
        <h3 class="two-factor-email__title">
          {{ $t('searchByEmail/checkTitle') }}
        </h3>
        <p class="two-factor-email__subtitle">
          {{ $t('searchByEmail/checkSubtitle') }}: <b>{{ form.email }}</b>
        </p>
      </header>
      <div class="two-factor-email__warning">
        {{ $t('searchByEmail/checkWarningTitle') }}
        <ul>
          <li>
            {{ $t('searchByEmail/checkWarningText') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Modal from '@/components/modal/modal.vue';
import { emailStates } from "@/email-states";

export default {
  name: 'TwoFactorEmail',
  components: {
    Modal,
  },
  props: {
    isModal: Boolean,
    eventId: String,
    externalEventId: String,
  },
  data() {
    return {
      emailRegex: /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/,
      states: emailStates,
      state: '',
      isDirty: false,
      isLoading: false,
      form: {
        email: '',
      },
      emailServerError: '',
      emailErrorMessages: {
        required: this.$t('searchByEmail/requiredError'),
        format: this.$t('searchByEmail/formatError'),
        notFound: this.$t('searchByEmail/notFoundError'),
      },
      participants: [],
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['lang']),
    emailErrorType() {
      if (this.emailServerError) {
        return this.emailServerError;
      }
      if (!this.form.email) {
        return 'required';
      }
      if (!this.emailRegex.test(this.form.email)) {
        return 'format';
      }
      return '';
    },
    isValid() {
      return !this.emailErrorType;
    },
  },
  watch: {
    'form.email'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.emailServerError = '';
      }
    },
  },
  methods: {
    open() {
      this.isDirty = false;
      this.state = this.states.enter_email;
    },
    async sendCode() {
      this.isDirty = true;
      this.emailServerError = '';

      if (this.isValid) {
        this.isLoading = true;

        const payloadForToken = {
          eventId: this.$props.eventId,
          payload: { emailAddress: this.form.email },
          lang: this.lang,
        };
        const result = await this.getToken(payloadForToken);
        this.isLoading = false;

        if (result.status === 200) {
          this.state = this.states.check_email;
        } else {
          this.emailServerError = 'notFound';
        }
      }
    },
    async getToken(payload) {
      return await this.$store.dispatch('SearchState/getToken', payload);
    },
  },
  created() {
    if (!this.$props.isModal) {
      this.state = this.states.enter_email;
    }
  },
  mounted() {
    this.state = '';
  },
};
</script>


<style lang="scss" scoped>
  @import "./two-factor-search-email";
</style>
