<template>
  <div style="position: relative;" :id="itemId">
    <div v-if="checked" class="item__badge d-flex">
      <div class="badge__text">
        {{ $t('resultsItem/inCart') }}
      </div>
    </div>
    <div class="item__image" :class="checked ? 'item__image--pressed' : ''" @click.stop="onImageClick">
      <div class="image__container">
        <div class="image__sizer"
          :style="{'padding-bottom': isVertical ? '142.222%' : '69.4444%'}"
        >
        </div>
        <div
          :style="{'background-image': 'url(' + photoSrc + ')'}"
          class="image__image"
        ></div>
        <div v-if="!$root.isMobile" class="item__image-actions" :class="hover ? 'item__image-actions--hover' : ''">
          <div class="image-actions__container d-flex justify-space-between">
            <div v-if="productTypes.length > 0" style="position: relative;" @click.stop>
              <Button 
                @click="onOption(0)"
                variant="secondary"
                size="md"
                iconLeft="photo-in-frame"
                :text="$t('photoPreview/frame')"
                class="actions__button"
              ></Button>
            </div>

            <div v-else></div>
          
            <div @click.stop>
              <Button 
                @click.stop="onAddToCart"
                variant="secondary"
                :view="checked ? 'success' : ''"
                size="md"
                class="actions__button"
                :class="checked ? 'actions__button--in-cart' : ''"
                :disabledQuiet="checked || adding"
              >
                <slot name="before" v-if="!checked">
                  <span class="rr-button__icon rr-button__icon_auto">
                    <base-icon v-if="theme === '' && !adding" width="18" height="18" viewBox="0 0 20 20">
                      <icon-shopping-cart2 fill="currentColor" />
                    </base-icon>
                    <base-icon v-if="theme !== '' && !adding" width="18" height="18" viewBox="0 0 24 24">
                      <icon-shopping-cart fill="currentColor" opacity="1" />
                    </base-icon>
                    <Loader v-if="adding" size="18" fill="var(--loader-dark, rgba(0, 0, 0, 0.87))" />
                  </span>
                </slot>
                <slot>
                  <span v-if="!checked && !adding" class="rr-button__text">{{ formatAmount(amount) }}</span>
                  <span v-if="checked && !adding" class="rr-button__text">{{ $t('resultsItem/inCart') }}</span>
                </slot>
              </Button>
            </div>  
            
          </div>        
        </div>        
      </div>
    </div>
    
    <div v-if="false && $root.isMobile" class="item__actions">
      <div class="d-flex" :class="productTypes.length > 0 ? 'justify-space-between' : 'justify-end'">
        <div v-if="productTypes.length > 0" style="position: relative;">
          <Button 
            @click="onOption(0)"
            variant="secondary"
            size="md"
            iconLeft="photo-in-frame"
            :text="$t('photoPreview/frame')"
            class="actions__button"
          ></Button>
        </div>
      
        <div>
          <Button 
            @click="onAddToCart"
            variant="secondary"
            :view="checked ? 'success' : ''"
            size="md"
            class="actions__button"
            :class="checked ? 'actions__button--in-cart' : ''"
            :disabledQuiet="checked || adding"
          >
            <slot name="before" v-if="!checked">
              <span class="rr-button__icon rr-button__icon_auto">
                <base-icon v-if="theme === '' && !adding" width="18" height="18" viewBox="0 0 20 20">
                  <icon-shopping-cart2 fill="currentColor" />
                </base-icon>
                <base-icon v-if="theme !== '' && !adding" width="18" height="18" viewBox="0 0 24 24">
                  <icon-shopping-cart fill="currentColor" opacity="1" />
                </base-icon>
                <Loader v-if="adding" size="18" fill="var(--loader-dark, rgba(0, 0, 0, 0.87))" />
              </span>
            </slot>
            <slot>
              <span v-if="!checked && !adding" class="rr-button__text">{{ formatAmount(amount) }}</span>
              <span v-if="checked && !adding" class="rr-button__text">{{ $t('resultsItem/inCart') }}</span>
            </slot>
          </Button>
        </div>  

        
      </div>
    </div>    
  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Loader from '@/components/loader.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconShoppingCart from '@/components/icons/icon-shopping-cart.vue';
import IconShoppingCart2 from '@/components/icons/icon-shopping-cart2.vue';
import Common from '@/plugins/common';

export default {
  name: 'ResultsItem',
  props: {
    photoId: String,
    photoSrc: String,
    checked: Boolean,
    adding: Boolean,
    productTypes: Array,
    amount: Number,
    isVertical: Boolean,
    itemHovered: Boolean,
  },
  components: {
    Loader,
    BaseIcon,
    IconShoppingCart,
    IconShoppingCart2,
  },
  data() {
    return {
      itemId: '',
      hover: false,
      overMenu: false,
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
  },
  watch: {
    '$props.itemHovered'() {
      if (this.$props.itemHovered) {
        this.hover = true;  
      } else {
        this.hover = false;
      }
    },
  },
  methods: {
    formatAmount(amount) {
      amount = parseFloat(amount.toFixed(2));
      return Common.formatAmount(amount, false);
    },
    onImageClick() {
      this.$emit('imageclick');
    },
    onOption(index) {
      this.$emit('optionclick', { photoId: this.$props.photoId, productIndex: index });
    },
    onAddToCart() {
      this.$emit('addtocart');
    },
  },
  mounted() {
    this.itemId = "results-item-" + this.$props.photoId;
  },
}
</script>


<style lang="scss" scoped>
  @import "./results-item";
</style>
