<template>
  <div class="product-slider" id="product-slider">
    <v-slide-group 
      v-model="index"
      ref="productSlider"
      prev-icon="" 
      next-icon=""
    >
      <v-slide-item
        v-for="(item, index2) in frameProducts"
        :key="index2"
      >
        <template v-slot:default>
          <div
            class="product-slider__item"
            :class="$root.isMobile ? '' : 'item--large'"
            @click.stop="onAddCart(item.productId)"
          >
            <div v-if="item.productType === productTypes.frame" class="item__badge">
              {{ $t('productSlider/onePhoto') }}
            </div>
            <div class="item__image" :class="$root.isMobile ? '' : 'image--large'">
              <img :src="getItemImage(item)" style="width: 100%; max-width: 100%;" />
            </div>
            <div class="item__price">
              {{ formatAmount(item.amount) }}
            </div>
            <div class="item__title text-wrap" :class="$root.isMobile ? '' : 'title--large'">
              {{item.title}}
            </div>
          </div>
        </template>
      </v-slide-item>
    </v-slide-group>

    <div 
      v-if="$root.isMobile && frameProducts.length < 6"
      class="product-slider__item-placeholder"
      :style="{ 'width': placeholderWidth.toString() + 'px' }"
    >
    </div>

    <div 
      v-if="!$root.isMobile && frameProducts.length < 6"
      class="product-slider__item-placeholder product-slider__item-placeholder--large"
      :style="{ 'width': 'calc(100% - ' + placeholderWidth.toString() + 'px)' }"
    >
    </div>

    <div 
      v-if="$root.isMobile"
      class="product-slider__dots"
    >
      <div 
        v-for="(item, index2) in frameProducts.length"
        :key="index2"
        @click.stop="onGoMobilePage(index2)"
        class="dots__dot"
        :class="index2 == index ? 'dots__dot--active' : ''"
      >
      </div>
    </div>
    <div 
      v-if="!$root.isMobile && frameProducts.length > 4"
      class="product-slider__dots"
    >
      <div 
        v-for="(page, index2) in desktopPagesCount"
        :key="index2"
        @click.stop="onGoDesktopPage(index2)"
        class="dots__dot"
        :class="index2 == desktopPage ? 'dots__dot--active' : ''"
      >
      </div>
    </div>

    <div v-if="!$root.isMobile && index != 0 && frameProducts.length > 5" class="product-slider__nav-icon nav-icon--left">
      <Button 
        @click="goPrevItem"
        variant="clear"
        view="light"
        shape="circle"
        size="lg"
      >
        <slot name="before">
          <span class="rr-button__icon rr-button__icon_auto">
            <base-icon width="47" height="47" viewBox="0 0 48 48">
              <icon-arrow-left fill="black" />
            </base-icon>
          </span>
        </slot>
      </Button>
    </div>
    <div v-if="!$root.isMobile && index != frameProducts.length-1 && frameProducts.length > 5" class="product-slider__nav-icon nav-icon--right">
      <Button 
        @click="goNextItem"
        variant="clear"
        view="light"
        shape="circle"
        size="lg"
      >
        <slot name="before">
          <span class="rr-button__icon rr-button__icon_auto">
            <base-icon width="47" height="47" viewBox="0 0 48 48">
              <icon-arrow-right fill="black" />
            </base-icon>
          </span>
        </slot>
      </Button>
    </div>

  </div>
</template>


<script lang="js">
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconArrowLeft from '@/components/icons/icon-arrow-left.vue';
import IconArrowRight from '@/components/icons/icon-arrow-right.vue';
import { ProductTypes } from '@/product-types';
import Common from '@/plugins/common';

export default {
  name: 'ProductSlider',
  props: {
    options: Object,
  },
  components: {
    BaseIcon,
    IconArrowLeft,
    IconArrowRight,
  },
  data() {
    return {
      index: 0,
      desktopPage: 0,
      placeholderWidth: 0,
      slider: null,
      scrollFromNav: false,
      productTypes: ProductTypes,
    };
  },
  computed: {
    frameProducts() {
      return this.$props.options.frameProducts;
    },
    eventId() {
      return this.$props.options.eventId;
    },
    byFace() {
      return this.$props.options.byFace;
    },
    runnerNum() {
      return this.$props.options.runnerNum;
    },
    selfieId() {
      return this.$props.options.selfieId;
    },
    personId() {
      return this.$props.options.personId;
    },
    desktopPagesCount() {
      return Math.ceil(this.frameProducts.length / 6) + 1;
    },
    scrollOffset() {
      return this.slider ? this.slider.scrollOffset : 0;
    },
  },
  watch: {
    scrollOffset() {
      if (!this.$root.isMobile) return;
      
      if (this.scrollFromNav) {
        this.scrollFromNav = false;
        return;
      }

      this.scrollFromNav = true;

      if (this.scrollOffset < 0) {
        this.index = 0;
        return;
      }
      
      const index = Math.floor(this.scrollOffset / 264);
      if (index > this.frameProducts.length-1) {
        this.index = this.frameProducts.length-1;
        return;
      }
      this.index = index;
    },
  },
  methods: {
    formatAmount(amount, onlyInteger = false) {
      return Common.formatAmount(amount, onlyInteger);
    },
    getItemImage(item) {
      if (!item) return '';
      if (item.productType === this.productTypes.frame) return '/img/photo-frame.png';
      if (item.productType === this.productTypes.photosWithFrame) return '/img/all-photos-in-frame.svg';
      return '';
    },
    goPrevItem() {
      this.scrollFromNav = true;
      if (this.index - 6 >= 0) {
        this.index -= 6;
        this.desktopPage -= 1;
      } else {
        this.index = 0;
        this.desktopPage = 0;
      }
    },
    goNextItem() {
      this.scrollFromNav = true;
      if (this.index + 6 < this.frameProducts.length) {
        this.index += 6;
        this.desktopPage += 1;
      } else {
        this.index = this.frameProducts.length - 1;
        this.desktopPage = this.desktopPagesCount - 1;
      }
    },
    onGoDesktopPage(page) {
      this.scrollFromNav = true;
      if (page > this.desktopPage) {
        for (let i = this.desktopPage; i < page; i++) {
          if (this.index + 6 < this.frameProducts.length) {
            this.index += 6;
          } else {
            this.index = this.frameProducts.length - 1;
          }
        }
      } else if (page < this.desktopPage) {
        for (let i = this.desktopPage; i > page; i--) {
          if (this.index - 6 >= 0) {
            this.index -= 6;
          } else {
            this.index = 0;
          }
        }
      }
      this.desktopPage = page;
    },
    onGoMobilePage(page) {
      this.scrollFromNav = true;
      this.index = page;
    },
    onAddCart(productId) {
      this.$emit('addcart', { productId: productId });
    },
    updateWidth() {
      if (this.$root.isMobile) {
        const container = document.getElementById('product-slider');
        if (container == null) return;
        
        this.placeholderWidth = container.offsetWidth - ((264 + 12) * this.frameProducts.length);
        if (this.placeholderWidth < 0) this.placeholderWidth = 0;

      } else {
        this.placeholderWidth = (219 + 12) * this.frameProducts.length;
      }
    },
  },
  created() {
    this.updateWidth();
    window.addEventListener('resize', () => {
        setTimeout(() => { this.updateWidth(); }, 250);
      }
    );
  },
  mounted() {
    this.updateWidth();
    this.slider = this.$refs.productSlider;
  },
}
</script>


<style lang="scss" scoped>
  @import "./product-slider";
</style>
