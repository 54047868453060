var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"two-factor-email"},[_c(_vm.isModal ? 'Modal' : 'div',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isModal ? false : _vm.state === _vm.states.enter_email),expression:"isModal ? false : state === states.enter_email"}],ref:"enterEmail",tag:"div",on:{"afterClose":function($event){_vm.state = null}}},'div',_vm.isModal
      ? {
        name: 'two-factor-email-enter',
        active: _vm.state === _vm.states.enter_email,
        title: _vm.$t('searchByEmail/title'),
        isDefault: true,
      }
      : null,false),[_c('Alert',{staticClass:"two-factor-email__form-alert",attrs:{"state":"warning","title":_vm.$t('searchByEmail/alertTitle'),"text":_vm.$t('searchByEmail/alertText'),"icon":"lock","noAction":""}}),_c('form',{staticClass:"two-factor-email__form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendCode.apply(null, arguments)}}},[_c('Field',{staticClass:"two-factor-email__form-field",attrs:{"label":_vm.$t('searchByEmail/label'),"labelSize":"sm","labelFor":"email"},scopedSlots:_vm._u([{key:"msg",fn:function(){return [((_vm.isDirty || _vm.emailServerError) && _vm.emailErrorMessages[_vm.emailErrorType])?_c('div',{staticClass:"two-factor-email__form-error"},[_vm._v(" "+_vm._s(_vm.emailErrorMessages[_vm.emailErrorType])+" ")]):_vm._e()]},proxy:true}])},[_c('Input',{staticClass:"two-factor-email__form-input",attrs:{"id":"email","type":"email","placeholder":"your@email.com","state":(_vm.isDirty || _vm.emailServerError) && _vm.emailErrorType ? 'error' : null,"wide":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_c('Button',{staticClass:"two-factor-email__form-button",attrs:{"variant":"primary","type":"submit","text":_vm.$t('searchByEmail/sendButton'),"disabled":_vm.isDirty && !_vm.isValid,"loading":_vm.isLoading,"mobile":""}})],1)],1)],1),_c(_vm.isModal ? 'Modal' : 'div',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isModal ? false : _vm.state === _vm.states.check_email),expression:"isModal ? false : state === states.check_email"}],ref:"checkEmail",tag:"div",on:{"afterClose":function($event){_vm.state = null}}},'div',_vm.isModal
      ? {
        name: 'two-factor-email-check',
        active: _vm.state === _vm.states.check_email,
        title: _vm.$t('searchByEmail/checkTitle'),
        subtitle: ((_vm.$t('searchByEmail/checkSubtitle')) + ": <b>" + (_vm.form.email) + "</b>"),
        isDefault: true,
      }
      : null,false),[(!_vm.isModal)?_c('header',{staticClass:"two-factor-email__heading"},[_c('h3',{staticClass:"two-factor-email__title"},[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/checkTitle'))+" ")]),_c('p',{staticClass:"two-factor-email__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/checkSubtitle'))+": "),_c('b',[_vm._v(_vm._s(_vm.form.email))])])]):_vm._e(),_c('div',{staticClass:"two-factor-email__warning"},[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/checkWarningTitle'))+" "),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/checkWarningText'))+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }