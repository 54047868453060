


































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "NotFoundPhotos",
  props: {
    fill: {
      type: String,
      default: "var(--color-primary)"
    }
  },
})
export default class NotFoundPhotos extends Vue {
}
