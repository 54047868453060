<template>
  <div
    class="two-factor-number"
    ref="modal"
    :is="isModal ? 'Modal' : 'div'"
    v-bind="isModal
      ? {
        name: 'two-factor-number',
        title: getDialogTitle(),
        isDefault: true,
      }
      : null"
  >
    <form
      class="two-factor-number__form"
      @submit.prevent="submit"
      novalidate
    >
      <Field
        class="two-factor-number__field"
        :label="$t('searchByNumber/labelNumber')"
        labelSize="sm"
        labelFor="startNumber"
      >
        <Input
          class="two-factor-number__bib"
          v-model.trim="form.startNumber"
          v-mask="'#####'"
          id="startNumber"
          placeholder="00000"
          type="tel"
          autocomplete="off"
          :state="(isDirty || startNumberServerError) && startNumberErrorType
            ? 'error'
            : null"
          noStateIcons
        ></Input>

        <template v-slot:msg>
          <div
            v-if="(isDirty || startNumberServerError)
              && startNumberErrorMessages[startNumberErrorType]"
            class="two-factor-number__field-error"
          >
            {{ startNumberErrorMessages[startNumberErrorType] }}
          </div>
        </template>
      </Field>

      <Field
        v-show="byNumberWithBirthDate && selectedSecondaryFactor === 'birthDate'"
        class="two-factor-number__field"
        labelSize="sm"
      >
        <div
          v-if="!$root.isMobile"
          class="two-factor-number__birth two-factor-number__birth--separate"
        >
          <Input
            v-model.trim="form.birthDay"
            v-mask="'##'"
            id="birthDay"
            :placeholder="$t('searchByNumber/DD')"
            type="tel"
            autocomplete="off"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            @change="form.birthDay = $options.filters.leadingZero(form.birthDay, 2)"
          ></Input>
          <Input
            v-model.trim="form.birthMonth"
            v-mask="'##'"
            :placeholder="$t('searchByNumber/MM')"
            type="tel"
            autocomplete="off"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            @change="form.birthMonth = $options.filters.leadingZero(form.birthMonth, 2)"
          ></Input>
          <Input
            v-model.trim="form.birthYear"
            v-mask="'####'"
            :placeholder="$t('searchByNumber/YYYY')"
            type="tel"
            autocomplete="off"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            style="width: 78px;"
            @change="form.birthYear = $options.filters.leadingZero(form.birthYear, 4)"
          ></Input>
        </div>

        <div
          v-if="$root.isMobile"
          class="two-factor-number__birth two-factor-number__birth--single"
        >
          <Input
            id="birthDate"
            icon-right="calendar"
            :placeholder="`${$t('searchByNumber/DD')}.${$t('searchByNumber/MM')}.${$t('searchByNumber/YYYY')}`"
            :inputModel="birthDate ? `${form.birthDay}.${form.birthMonth}.${form.birthYear}` : null"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            readonly
            mobile
          ></Input>
          <input
            class="date-input-single__input"
            v-model="birthDate"
            type="date"
            autocomplete="off"
            :min="availableDates[0]"
            :max="availableDates[1]"
            id="birth-date-input"
          >
        </div>

        <template v-slot:afterLabel>
          <label
            class="rr-label"
            :for="$root.isMobile ? 'birthDate' : 'birthDay'"
          >
            <span v-if="!moreThanOneSecondaryFactor" class="code-or-surname__label">
              <b>{{ $t('searchByNumber/labelBirthDate') }}</b>
            </span>
            <span v-else class="code-or-surname__label">
              <b>{{ $t('searchByNumber/labelBirthDateShort') }}</b>
              <span v-if="byNumberWithCode"> {{ $t('searchResults/or') }} </span>
              <a v-if="byNumberWithCode" href="#" @click.prevent="onSwitchToCode">{{ $t('searchByNumber/labelCodeShort') }}</a>
              <span v-if="byNumberWithSurname"> {{ $t('searchResults/or') }} </span>
              <a v-if="byNumberWithSurname" href="#" @click.prevent="onSwitchToSurname">{{ $t('searchByNumber/labelSurnameShort') }}</a>
            </span>
          </label>
        </template>

        <template v-slot:msg>
          <div
            v-if="isDirty && birthDateErrorMessages[birthDateErrorType]"
            class="two-factor-number__field-error"
          >
            {{ birthDateErrorMessages[birthDateErrorType] }}
          </div>

          <div class="two-factor-number__field-description">
            {{ $t('searchByNumber/info') }}
          </div>
        </template>
      </Field>

      <Field
        v-show="byNumberWithCode && selectedSecondaryFactor === 'code'"
        class="two-factor-number__field"
        labelSize="sm"
      >
        <div class="two-factor-number__code-or-surname">
          <Input
            v-model.trim="form.code"
            id="code"
            type="text"
            autocomplete="off"
            :state="isDirty && codeErrorType ? 'error' : null"
            noStateIcons
            mobile
          ></Input>
        </div>

        <template v-slot:afterLabel>
          <label
            class="rr-label"
            for="code"
          >
            <span v-if="!moreThanOneSecondaryFactor" class="code-or-surname__label">
              <b>{{ $t('searchByNumber/labelCode') }}</b>
            </span>
            <span v-else class="code-or-surname__label">
              <a v-if="byNumberWithBirthDate" href="#" @click.prevent="onSwitchToBirthDate">{{ $t('searchByNumber/labelBirthDateShort') }}</a>
              <span v-if="byNumberWithBirthDate"> {{ $t('searchResults/or') }} </span>
              <b>{{ $t('searchByNumber/labelCodeShort') }}</b>
              <span v-if="byNumberWithSurname"> {{ $t('searchResults/or') }} </span>
              <a v-if="byNumberWithSurname" href="#" @click.prevent="onSwitchToSurname">{{ $t('searchByNumber/labelSurnameShort') }}</a>
            </span>
          </label>
        </template>

        <template v-slot:msg>
          <div
            v-if="isDirty && codeErrorMessages[codeErrorType]"
            class="two-factor-number__field-error"
          >
            {{ codeErrorMessages[codeErrorType] }}
          </div>

          <div class="two-factor-number__field-description">
            {{ form.code && codeErrorMessages[codeErrorType] ? $t('searchByNumber/infoCodeFormat') : $t('searchByNumber/infoCode') }}
          </div>
        </template>
      </Field>

      <Field
        v-show="byNumberWithSurname && selectedSecondaryFactor === 'surname'"
        class="two-factor-number__field"
        labelSize="sm"
      >
        <div class="two-factor-number__code-or-surname">
          <Input
            v-model.trim="form.surname"
            id="surname"
            type="text"
            autocomplete="off"
            :state="isDirty && surnameErrorType ? 'error' : null"
            noStateIcons
            mobile
          ></Input>
        </div>

        <template v-slot:afterLabel>
          <label
            class="rr-label"
            for="surname"
          >
            <span v-if="!moreThanOneSecondaryFactor" class="code-or-surname__label">
              <b>{{ $t('searchByNumber/labelSurname') }}</b>
            </span>
            <span v-else class="code-or-surname__label">
              <a v-if="byNumberWithBirthDate" href="#" @click.prevent="onSwitchToBirthDate">{{ $t('searchByNumber/labelBirthDateShort') }}</a>
              <span v-if="byNumberWithBirthDate"> {{ $t('searchResults/or') }} </span>
              <a v-if="byNumberWithCode" href="#" @click.prevent="onSwitchToCode">{{ $t('searchByNumber/labelCodeShort') }}</a>
              <span v-if="byNumberWithCode"> {{ $t('searchResults/or') }} </span>
              <b>{{ $t('searchByNumber/labelSurnameShort') }}</b>
            </span>
          </label>
        </template>

        <template v-slot:msg>
          <div
            v-if="isDirty && surnameErrorMessages[surnameErrorType]"
            class="two-factor-number__field-error"
          >
            {{ surnameErrorMessages[surnameErrorType] }}
          </div>

          <div class="two-factor-number__field-description">
            {{ $t('searchByNumber/info') }}
          </div>
        </template>
      </Field>

      <Alert
        v-if="isNotFound"
        class="two-factor-number__form-alert"
        state="error"
        :text="$t('searchByNumber/notFoundPhotos')"
        noIcon
        noAction
        style="border: 0;"
      ></Alert>
      <Alert
        v-if="isNotValid"
        class="two-factor-number__form-alert"
        state="error"
        :text="$t('searchByNumber/checkTheData')"
        noIcon
        noAction
        style="border: 0;"
      ></Alert>

      <div
        v-if="isModal"
        class="two-factor-number__divider"
      ></div>

      <Button
        class="two-factor-number__form-button"
        variant="primary"
        size="lg"
        wide
        type="submit"
        :disabled="(isDirty && !isValid) || isActionDisabled()"
        :loading="isLoading"
        v-bind="isModal
          ? { text: $t('searchByNumber/buttonContinue'), iconRight: 'right' }
          : { text: $t('searchByNumber/buttonFind') }
        "
      ></Button>
    </form>
  </div>
</template>


<script lang="js">
import { mask } from 'vue-the-mask';
import Modal from '@/components/modal/modal.vue';

export default {
  name: 'TwoFactorNumber',
  components: {
    Modal,
  },
  directives: {
    mask,
  },
  props: {
    isModal: {
      type: Boolean,
      default: true,
    },
    fromNotFound: Boolean,
    eventId: String,
    externalEventId: String,
    searching: Object,
  },
  filters: {
    leadingZero(value, size) {
      if (value === '') {
        return value;
      }

      return value.toString().padStart(size, '0');
    },
  },
  data() {
    return {
      isDirty: false,
      isLoading: false,
      isNotFound: false,
      isNotValid: false,
      form: {
        startNumber: '',
        birthDay: '',
        birthMonth: '',
        birthYear: '',
        code: '',
        surname: '',
      },
      startNumberServerError: false,
      startNumberErrorMessages: {
        required: this.$t('searchByNumber/requiredNumberError'),
        notFound: this.$t('searchByNumber/notFoundNumberError'),
      },
      birthDateErrorMessages: {
        required: this.$t('searchByNumber/requiredDateError'),
        format: this.$t('searchByNumber/formatDateError'),
      },
      codeError: false,
      codeErrorMessages: {
        required: this.$t('searchByNumber/requiredCodeError'),
      },
      surnameError: false,
      surnameErrorMessages: {
        required: this.$t('searchByNumber/requiredSurnameError'),
      },
      availableDates: [
        '1870-01-01',
        `${new Date().getFullYear()}-01-01`,
      ],
      inputDate: null,
      focusListener: null,
      selectedSecondaryFactor: '',
    };
  },
  computed: {
    byNumberWithBirthDate() {
      return this.$props.searching.byNumberWithBirthDate === true;
    },
    byNumberWithCode() {
      return this.$props.searching.byNumberWithCode === true;
    },
    byNumberWithSurname() {
      return this.$props.searching.byNumberWithSurname === true;
    },
    moreThanOneSecondaryFactor() {
      return [this.byNumberWithBirthDate, this.byNumberWithCode, this.byNumberWithSurname].filter((i) => i === true).length > 1;
    },
    verificationError() {
      return this.$store.getters['SearchState/verificationError'];
    },
    verificationCodeValid() {
      return this.$store.getters['SearchState/isVerificationCodeValid'];
    },
    searchToken() {
      return this.$store.getters['SearchState/searchToken'];
    },
    photos() {
      return this.$store.getters['SearchState/photos'];
    },
    photosPerPage() {
      if (!this.$root.isDesktop) return 12;
      return 50;
    },
    birthDate: {
      get: function() {
        const dateParts = [
          this.form.birthYear,
          this.form.birthMonth,
          this.form.birthDay,
        ];

        if (dateParts.some((v) => !v)) {
          return '';
        }

        return dateParts.join('-');
      },
      set: function(value) {
        if (!value || value.split('-').length < 3) {
          return;
        }

        [
          this.form.birthYear,
          this.form.birthMonth,
          this.form.birthDay,
        ] = value.split('-');
      },
    },
    startNumberErrorType() {
      if (this.startNumberServerError) {
        return 'notFound';
      }

      if (!this.form.startNumber || +this.form.startNumber < 1) {
        return 'required';
      }

      return '';
    },
    birthDateErrorType() {
      if (!this.birthDate) {
        return 'required';
      }

      const date = new Date(this.birthDate);
      if (isNaN(+date)
          || date.getFullYear() !== +this.form.birthYear
          || date.getMonth() + 1 !== +this.form.birthMonth
          || date.getDate() !== +this.form.birthDay
      ) {
        return 'format';
      }

      if (date < new Date(this.availableDates[0])
          || date > new Date(this.availableDates[1])
      ) {
        return 'format';
      }

      return '';
    },
    codeErrorType() {
      if (!this.form.code) {
        return 'required';
      }

      const template = /^[a-zA-Z\d]+$/;
      if (this.form.code.length !== 5 || !template.test(this.form.code)) {
        return 'required';
      }

      return '';
    },
    surnameErrorType() {
      if (!this.form.surname) {
        return 'required';
      }

      return '';
    },
    isValid() {
      return !this.startNumberErrorType && (
        (this.selectedSecondaryFactor === 'birthDate' && !this.birthDateErrorType)
        || (this.selectedSecondaryFactor === 'code' && !this.codeErrorType)
        || (this.selectedSecondaryFactor === 'surname' && !this.surnameErrorType)
      );
    },
  },
  watch: {
    'form.startNumber'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.startNumberServerError = false;
        this.isNotFound = false;
        this.isNotValid = false;
      }
    },
    birthDate(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isNotFound = false;
        this.isNotValid = false;
      }
    },
    'form.code'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isNotFound = false;
        this.isNotValid = false;
      }
    },
    'form.surname'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isNotFound = false;
        this.isNotValid = false;
      }
    },
  },
  methods: {
    open() {
      this.isDirty = false;
      const modal = this.$refs.modal;
      modal.open();
      setTimeout(() => { this.setInputHandler(); }, 500);
    },
    async submit() {
      this.isDirty = true;
      this.isNotFound = false;
      this.isNotValid = false;
      this.startNumberServerError = false;

      if (!this.isValid) {
        return;
      }

      this.isLoading = true;

      const isCodeValid = await this.isVerificationCodeValid();
      if (!isCodeValid) {
        this.isNotValid = true;
        this.isLoading = false;
        return;
      }

      const payloadForToken = {
        eventId: this.$props.eventId,
        payload: {
          startNumber: this.form.startNumber,
          ...this.getSecondaryFactor(),
        },
      };
      const tokenResult = await this.getToken(payloadForToken);
      if (tokenResult.status !== 200) {
        this.startNumberServerError = true;
        return;
      }

      const payloadForPhotos = {
        eventId: this.$props.eventId,
        token: this.searchToken ? this.searchToken.value : '',
        pagination: { offset: 0, count: this.photosPerPage },
      };
      await this.searchByTag(payloadForPhotos);

      if (this.photos.length === 0) {
        this.isNotFound = true;
      } else {
        if (this.$props.isModal) {
          const modal = this.$refs.modal;
          await modal.close();
        }
        this.removeInputHandler();

        const params = {
          id: this.$props.externalEventId,
          number: this.form.startNumber,
          ...this.getSecondaryFactor(),
        };

        if (this.$props.fromNotFound === true) {
          this.$emit('doNewSearch', params);
        } else {
          await this.$router.push({
            name: 'search-from-url',
            params,
          });
        }
      }

      this.isLoading = false;
    },
    async isVerificationCodeValid() {
      let code = '';
      if (this.selectedSecondaryFactor === 'birthDate') code = this.birthDate;
      if (this.selectedSecondaryFactor === 'code') code = this.form.code.toUpperCase();
      if (this.selectedSecondaryFactor === 'surname') code = this.form.surname;

      await this.verifyCode({
        number: this.form.startNumber,
        code,
      });

      if (this.verificationError) return false;
      if (this.verificationCodeValid) return true;
      return false;
    },
    onSwitchToBirthDate() {
      this.selectedSecondaryFactor = 'birthDate';
    },
    onSwitchToCode() {
      this.selectedSecondaryFactor = 'code';
    },
    onSwitchToSurname() {
      this.selectedSecondaryFactor = 'surname';
    },
    setInputHandler() {
      const inputDate = document.getElementById('birth-date-input');
      if (!inputDate) return;

      this.inputDate = inputDate;

      const focusListener = function() {
        if (this.getAttribute('type') === 'date') {
          if (this.showPicker) this.showPicker();
        }
      };

      inputDate.addEventListener('click', focusListener);
      this.focusListener = focusListener;
    },
    removeInputHandler() {
      if (this.inputDate && this.focusListener) {
        this.inputDate.removeEventListener('focus', this.focusListener);
        this.inputDate = null;
        this.focusListener = null;
      }
    },
    setSelectedSecondaryFactor() {
      if (this.byNumberWithBirthDate) {
        this.selectedSecondaryFactor = 'birthDate';
        return;
      }
      if (this.byNumberWithCode) {
        this.selectedSecondaryFactor = 'code';
        return;
      }
      if (this.byNumberWithSurname) {
        this.selectedSecondaryFactor = 'surname';
        return;
      }
    },
    getSecondaryFactor() {
      if (this.selectedSecondaryFactor === 'birthDate') return {
        birthDate: this.birthDate,
      };
      if (this.selectedSecondaryFactor === 'code') return {
        code: this.form.code.toUpperCase(),
      };
      if (this.selectedSecondaryFactor === 'surname') return {
        surname: this.form.surname,
      };
      return null;
    },
    getDialogTitle() {
      if (this.selectedSecondaryFactor === 'birthDate') return this.$t('searchByNumber/titleBirthDate').toString();
      if (this.selectedSecondaryFactor === 'code') return this.$t('searchByNumber/titleCode').toString();
      if (this.selectedSecondaryFactor === 'surname') return this.$t('searchByNumber/titleSurname').toString();
      return null;
    },
    isActionDisabled() {
      if (this.byNumberWithCode && this.selectedSecondaryFactor === 'code') {
        return this.isValid === false;
      }

      return false;
    },
    async verifyCode(payload) {
      return await this.$store.dispatch('SearchState/verifyCode', payload);
    },
    async getToken(payload) {
      return await this.$store.dispatch('SearchState/getToken', payload);
    },
    async searchByTag(payload) {
      return await this.$store.dispatch('SearchState/searchByTag', payload);
    },
  },
  mounted() {
    this.setSelectedSecondaryFactor();
  },
};
</script>


<style lang="scss" scoped>
  @import "./two-factor-search-number";
</style>
