<template>
  <div class="two-factor-selfie">
    <Modal
      :active="state === states.no_camera"
      ref="noCamera"
      name="two-factor-selfie-no-camera"
      :title="$t('searchBySelfie/noCameraTitle')"
      :subtitle="$t('searchBySelfie/noCameraSubtitle')"
      isDefault
      @afterClose="clearState"
    >
      <template v-slot:icon>
        <Icon
          name="warning"
          style="color: #FAAD14"
        ></Icon>
      </template>
      <div class="rr-modal__item">
        <Button
          variant="secondary"
          size="lg"
          :text="$t('searchBySelfie/noCameraButton')"
          fullHeightLanes
          @click="() => $refs.noCamera.close()"
        ></Button>
      </div>
    </Modal>

    <Modal
      :active="state === states.no_access"
      ref="noAccess"
      name="two-factor-selfie-no-access"
      :title="$t('searchBySelfie/unableCameraTitle')"
      isDefault
      @afterClose="clearState"
    >
      <template v-slot:icon>
        <Icon
          name="warning"
          style="color: #FAAD14"
        ></Icon>
      </template>
      <div class="rr-modal__item">
        <div class="two-factor-selfie__warning">
          {{ $t('searchBySelfie/unableCameraWarningTitle') }}
          <ul>
            <li
              v-for="item in $t('searchBySelfie/unableCameraWarningList')"
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="rr-modal__item">
        <Button
          variant="secondary"
          size="lg"
          :text="$t('searchBySelfie/unableCameraButton')"
          fullHeightLanes
          @click="() => $refs.noAccess.close()"
        ></Button>
      </div>
    </Modal>

    <Modal
      :active="state === states.take_selfie"
      ref="takeSelfie"
      name="two-factor-selfie-take"
      :title="$t('searchBySelfie/title')"
      isDefault
      @close="closeCamera"
      @afterClose="clearState"
    >
      <div class="rr-modal__item">
        <div class="two-factor-selfie__camera">
          <Loader
            v-if="isLoading"
            class="two-factor-selfie__camera-loader"
            size="24"
            fill="var(--color-primary)"
          ></Loader>
          <video
            v-show="isCameraOpen && !isPhotoTaken"
            ref="camera"
            autoplay muted playsinline
          ></video>
          <canvas
            v-show="isCameraOpen && isPhotoTaken"
            ref="canvas"
            :width="width" :height="height"
          ></canvas>
          <div
            v-show="isPhotoTaken"
            class="two-factor-selfie__camera-shutter"
            :class="{'flash' : isShotPhoto}"
          ></div>
        </div>
      </div>
      <div class="rr-modal__item">
        <div class="two-factor-selfie__actions">
          <Button
            v-if="!isPhotoTaken"
            class="two-factor-selfie__action"
            variant="primary"
            size="lg"
            :text="$t('searchBySelfie/buttonTake')"
            icon-right="selfie"
            :disabled="isLoading"
            mobile
            fullHeightLanes
            @click="takePhoto"
          ></Button>
          <template v-else>
            <Button
              class="two-factor-selfie__action"
              variant="primary"
              size="lg"
              :text="$t('searchBySelfie/buttonFind')"
              mobile
              fullHeightLanes
              @click="recognize"
            ></Button>
            <Button
              class="two-factor-selfie__action"
              variant="ghost"
              view="danger"
              size="lg"
              :text="$t('searchBySelfie/buttonDelete')"
              mobile
              fullHeightLanes
              @click="takePhoto"
            ></Button>
          </template>
        </div>
      </div>
      <template v-slot:note>
        <template v-if="isPhotoTaken">
          {{ $t('searchBySelfie/noteAgree') }}
        </template>
        <template v-else>
          {{ $t('searchBySelfie/noteDoNotKeep') }}
        </template>
      </template>
    </Modal>

    <v-dialog v-model="captureDialog" fullscreen persistent>
      <CapturePhoto
        :redraw="redrawImage.toString()"
        :mode="searchResult"
        @dateSet="onBirthDateSet"
        @searchSelfie="onSearchSelfie"
        @cancelled="onCloseCaptureDialog"
      />
    </v-dialog>
  </div>
</template>


<script lang="js">
import Modal from '@/components/modal/modal.vue';
import Loader from '@/components/loader.vue';
import CapturePhoto from '@/components/capture-photo/capture-photo.vue';
import { selfieStates } from '@/selfie-states';

export default {
  name: 'TwoFactorSelfie',
  components: {
    Modal,
    Loader,
    CapturePhoto,
  },
  props: {
    eventId: String,
    externalEventId: String,
    startNumber: String,
  },
  data() {
    return {
      os: function() {
        const userAgent = navigator.userAgent || navigator.vendor || (window).opera;
        if (/windows phone/i.test(userAgent)) {
          return 'Windows Phone';
        }
        if (/android/i.test(userAgent)) {
          return 'Android';
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return 'iOS';
        }
        return 'unknown';
      }(),
      isMobile: this.os !== 'unknown',
      isPortrait: false,
      width: 494,
      height: 370,
      states: selfieStates,
      state: '',
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      captureDialog: false,
      redrawImage: false,
      selectedFile: null,
      searchResult: '',
      personId: '',
      birthDate: '',
    };
  },
  computed: {
    event() {
      return this.$store.getters['EventsState/event'];
    },
    image() {
      return this.$store.getters['CaptureState/image'];
    },
    file() {
      return this.$store.getters['CaptureState/file'];
    },
    searchToken() {
      return this.$store.getters['SearchState/searchToken'];
    },
    photos() {
      return this.$store.getters['SearchState/photos'];
    },
    searchError() {
      return this.$store.getters['SearchState/searchError'];
    },
    isTokenExpired() {
      return this.$store.getters['SearchState/isTokenExpired'];
    },
    persons() {
      return this.$store.getters['SearchState/persons'];
    },
    selfieId() {
      return this.$store.getters['SearchState/selfieId'];
    },
    responseCode() {
      return this.$store.getters['SearchState/responseCode'];
    },
    photosPerPage() {
      if (!this.$root.isDesktop) return 12;
      return 50;
    },
  },
  watch: {
    captureDialog() {
      if (this.captureDialog) {
        this.setModalActive(true);
      } else {
        this.setModalActive(false);
      }
    },
  },
  methods: {
    open() {
      this.isLoading = true;
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.state = this.states.take_selfie;
      this.openCamera();
    },
    clearState() {
      this.state = '';
    },
    openCamera() {
      this.isLoading = true;

      const constraints = {
        audio: false,
        video: {
          width: this.width,
          height: this.height,
          facingMode: 'user',
        },
      };

      if (!navigator.mediaDevices) {
        this.state = this.states.no_access;
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          const camera = this.$refs.camera;
          camera.srcObject = stream;

          if (this.isMobile) {
            this.updateSize();
            window.addEventListener('orientationchange', this.updateSize);
          }

          this.isCameraOpen = true;
          this.state = this.states.take_selfie;
        })
        .catch((error) => {
          this.state = error?.name === 'NotFoundError'
              ? this.states.no_camera
              : this.states.no_access;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateSize() {
      setTimeout(() => {
        const isPortrait = window.innerWidth < window.innerHeight;

        if (this.isPortrait !== isPortrait) {
          this.isPortrait = isPortrait;
          const camera = this.$refs.camera;
          let tracks = camera ? camera.srcObject?.getTracks() : [];

          tracks.forEach((track) => {
            track.applyConstraints({
              width: !isPortrait ? this.width : this.height,
              height: !isPortrait ? this.height : this.width,
              facingMode: 'user',
            });
          });
        }
      });
    },
    closeCamera() {
      const camera = this.$refs.camera;
      let tracks = camera ? camera.srcObject?.getTracks() : [];

      tracks.forEach((track) => {
        track.stop();
      });

      window.removeEventListener('orientationchange', this.updateSize);
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const camera = this.$refs.camera;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.drawImage(camera, 0, 0, this.width, this.height);
    },
    async recognize() {
      const canvas = this.$refs.canvas;
      const selfie = canvas.toDataURL('image/jpeg');
      this.redrawImage = false;

      await this.loadImage(selfie);
      await this.loadFile(this.image.image.src);

      this.$store.state.selectedFile = this.file;
      await this.onOpenCaptureDialog();
      this.selectedFile = this.file;
      this.searchResult = 'searching';
      this.redrawImage = true;

      await this.recognizePhoto({ file: this.selectedFile, albumId: this.$props.eventId });

      if (!this.searchError) {
        if (this.persons.length == 1) {
          const personId = this.persons[0].personId;
          if (!personId) {
            await this.onCloseCaptureDialog();
          } else if (personId === 'undefined') {
            await this.onCloseCaptureDialog();
            this.closeCamera();
            this.clearState();
            await this.showResults();
          } else {
            this.personId = personId;
            this.askBirthDate();
          }
        } else {
          this.searchResult = 'no-face-selfie';
        }
      } else {
        if (this.responseCode == 413) {
          this.searchResult = 'too-big-file';
          return;
        } else if (this.responseCode == 500) {
          const result = await this.repeatSearch();
          if (!result) return;
        } else {
          this.searchResult = 'error';
          return;
        }
        await this.onCloseCaptureDialog();
      }
    },
    async repeatSearch() {
      await this.recognizePhoto({ file: this.selectedFile, albumId: this.$props.eventId });
      if (this.searchError && this.responseCode == 413) {
        this.searchResult = 'too-big-file';
        return false;
      } else if (this.searchError && this.responseCode == 500) {
        this.searchResult = 'error';
        return false;
      } else if (this.searchError) {
        this.searchResult = 'error';
        return false;
      }
      return true;
    },
    async askBirthDate() {
      this.searchResult = 'birth-date';
    },
    async onBirthDateSet(date) {
      this.birthDate = date;
      if (this.birthDate) {
        this.findPhotos(this.personId);
      }
    },
    async findPhotos(personId) {
      const payloadForToken = {
        eventId: this.$props.eventId,
        payload: {
          personId: personId,
          birthDate: this.birthDate,
        },
      }
      let tokenResult = await this.getToken(payloadForToken);
      if (tokenResult.status !== 200) {
        if (this.isTokenExpired && this.$props.startNumber) {
          const result = await this.getNewSearchToken({
            eventId: this.$props.eventId,
            payload: {
              startNumber: this.$props.startNumber,
              birthDate: this.birthDate,
            },
          });
          if (result) {
            payloadForToken.token = result.value;
            tokenResult = await this.getToken(payloadForToken);
            if (tokenResult.status !== 200) {
              this.searchResult = 'error';
              return;
            }
          }
        } else {
          this.searchResult = 'error';
          return;
        }
      }
      
      if (this.searchToken) {
        const payloadForPhotos = {
          eventId: this.$props.eventId,
          token: this.searchToken ? this.searchToken.value : '',
          pagination: { offset: 0, count: this.photosPerPage },
        };
        await this.searchByTag(payloadForPhotos);
        await this.onCloseCaptureDialog();
        this.closeCamera();
        this.clearState();
        if (this.photos.length > 0) {
          await this.showResults();
        } else {
          await this.showResults();
        }
      }
    },
    async onSearchSelfie() {
      this.onCloseCaptureDialog();
      this.takePhoto();
    },
    async onCloseCaptureDialog() {
      this.captureDialog = false;
    },
    async onOpenCaptureDialog() {
      this.captureDialog = true;
    },
    async showResults() {
      const personId = this.persons[0] ? this.persons[0].personId : '';

      await this.$router.push({
        name: 'search-from-url',
        params: {
          id: this.$props.externalEventId,
          number: `${this.selfieId}+${personId}`,
          birthDate: this.birthDate,
        }
      });
    },
    async getNewSearchToken(payload) {
      const tokenResult = await this.getToken(payload);
      if (tokenResult.status !== 200) return null;
      return this.searchToken;
    },
    async setModalActive(payload) {
      return await this.$store.dispatch('SettingsState/setModalActive', payload);
    },
    async loadImage(payload) {
      return await this.$store.dispatch('CaptureState/loadImage', payload);
    },
    async loadFile(payload) {
      return await this.$store.dispatch('CaptureState/loadFile', payload);
    },
    async getEventById(payload) {
      return await this.$store.dispatch('EventsState/getEventById', payload);
    },
    async getToken(payload) {
      return await this.$store.dispatch('SearchState/getToken', payload);
    },
    async recognizePhoto(payload) {
      return await this.$store.dispatch('SearchState/recognizePhoto', payload);
    },
    async searchByTag(payload) {
      return await this.$store.dispatch('SearchState/searchByTag', payload);
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./two-factor-search-selfie";
</style>
