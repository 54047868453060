<template>
  <div class="not-found">
    <div class="not-found__illustration" :class="$root.isMobile ? '' : 'not-found__illustration--large'">
      <base-illustration width="322" height="240" viewBox="0 0 322 240">
        <not-found-photos fill="var(--color-primary)" />
      </base-illustration>
    </div>
    <div class="not-found__header">
      {{ $t('searchResults/photoNotFound') }}
    </div>

    <div v-show="tagged" class="text-center">
      <div v-if="$slots.textTagged" class="not-found__text">
        <slot name="textTagged"></slot>
      </div>

      <div v-if="$slots.actionPrimary" class="not-found__search-by-tag">
        <slot name="actionPrimary"></slot>
      </div>

      <div v-if="$slots.actionPrimary && $slots.actionSecondary" class="not-found__divider d-flex justify-space-between">
        <div class="divider__line"></div>
        <div class="divider__text">{{ $t('searchResults/or') }}</div>
        <div class="divider__line"></div>
      </div>

      <div v-if="$slots.actionSecondary" class="not-found__search-by-face">
        <slot name="actionSecondary"></slot>
      </div>
    </div>

    <div v-show="!tagged" class="text-center">
      <div class="not-found__text">
        {{ $t('searchResults/photosNotTagged') }}
      </div>
      <div v-if="$slots.actionNotTagged" class="not-found__search-by-face search-by-face--filled">
        <slot name="actionNotTagged"></slot>
      </div>
    </div>
  </div>
</template>


<script lang="js">
import BaseIllustration from '@/components/base-illustration/base-illustration.vue';
import NotFoundPhotos from '@/components/illustrations/not-found-photos.vue';

export default {
  name: 'SearchNotFound',
  props: {
    tagged: Boolean,
  },
  components: {
    BaseIllustration,
    NotFoundPhotos,
  },
};
</script>


<style lang="scss" scoped>
  @import "./search-not-found";
</style>
