import { render, staticRenderFns } from "./product-slider.vue?vue&type=template&id=8730b6d6&scoped=true&"
import script from "./product-slider.vue?vue&type=script&lang=js&"
export * from "./product-slider.vue?vue&type=script&lang=js&"
import style0 from "./product-slider.vue?vue&type=style&index=0&id=8730b6d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8730b6d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VSlideGroup,VSlideItem})
