<template>
  <div class="two-factor-birthdate">
    <div class="two-factor-birthdate__title" v-html="$t('searchBySelfie/birthTitle')"></div>
    <div class="two-factor-birthdate__description" v-html="$t('searchByNumber/info')"></div>
    <form
      class="two-factor-birthdate__form"
      @submit.prevent="submit"
      novalidate
    >
      <Field
        class="two-factor-birthdate__field"
        :label="$t('searchBySelfie/birthDate')"
        labelSize="sm"
        :labelFor="$root.isMobile ? 'birthDate' : 'birthDay'"
      >
        <div
          v-if="!$root.isMobile"
          class="two-factor-birthdate__birth two-factor-birthdate__birth--separate"
        >
          <Input
            v-model.trim="form.birthDay"
            v-mask="'##'"
            id="birthDay"
            :placeholder="$t('searchByNumber/DD')"
            type="tel"
            autocomplete="off"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            @change="form.birthDay = $options.filters.leadingZero(form.birthDay, 2)"
          ></Input>
          <Input
            v-model.trim="form.birthMonth"
            v-mask="'##'"
            :placeholder="$t('searchByNumber/MM')"
            type="tel"
            autocomplete="off"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            @change="form.birthMonth = $options.filters.leadingZero(form.birthMonth, 2)"
          ></Input>
          <Input
            v-model.trim="form.birthYear"
            v-mask="'####'"
            :placeholder="$t('searchByNumber/YYYY')"
            type="tel"
            autocomplete="off"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            @change="form.birthYear = $options.filters.leadingZero(form.birthYear, 4)"
          ></Input>
        </div>

        <div
          v-if="$root.isMobile"
          class="two-factor-birthdate__birth two-factor-birthdate__birth--single"
        >
          <Input
            id="birthDate"
            icon-right="calendar"
            :placeholder="`${$t('searchByNumber/DD')}.${$t('searchByNumber/MM')}.${$t('searchByNumber/YYYY')}`"
            :inputModel="birthDate ? `${form.birthDay}.${form.birthMonth}.${form.birthYear}` : null"
            :state="isDirty && birthDateErrorType ? 'error' : null"
            noStateIcons
            readonly
            mobile
          ></Input>
          <input
            class="date-input-single__input"
            v-model="birthDate"
            type="date"
            autocomplete="off"
            :min="availableDates[0]"
            :max="availableDates[1]"
            id="birth-date-input"
          >
        </div>

        <template v-slot:msg>
          <div
            v-if="isDirty && birthDateErrorMessages[birthDateErrorType]"
            class="two-factor-birthdate__field-error"
          >
            {{ birthDateErrorMessages[birthDateErrorType] }}
          </div>

        </template>
      </Field>

      <Button
        class="two-factor-birthdate__form-button"
        variant="primary"
        size="lg"
        wide
        type="submit"
        :disabled="isDirty && !isValid"
        :loading="isLoading"
        v-bind="{ text: $t('searchByNumber/buttonContinue'), iconRight: 'right' }"
      ></Button>
    </form>
  </div>
</template>


<script lang="js">
import { mask } from 'vue-the-mask';

export default {
  name: 'TwoFactorBirthdate',
  directives: {
    mask,
  },
  filters: {
    leadingZero(value, size) {
      if (value === '') {
        return value;
      }

      return value.toString().padStart(size, '0');
    },
  },
  data() {
    return {
      isDirty: false,
      isLoading: false,
      form: {
        birthDay: '',
        birthMonth: '',
        birthYear: '',
      },
      birthDateErrorMessages: {
        required: this.$t('searchByNumber/requiredDateError'),
        format: this.$t('searchByNumber/formatDateError'),
      },
      availableDates: [
        '1870-01-01',
        `${new Date().getFullYear()}-01-01`,
      ],
      inputDate: null,
      focusListener: null,
    };
  },
  computed: {
    birthDate: {
      get: function() {
        const dateParts = [
          this.form.birthYear,
          this.form.birthMonth,
          this.form.birthDay,
        ];

        if (dateParts.some((v) => !v)) {
          return '';
        }

        return dateParts.join('-');
      },
      set: function(value) {
        if (!value || value.split('-').length < 3) {
          return;
        }

        [
          this.form.birthYear,
          this.form.birthMonth,
          this.form.birthDay,
        ] = value.split('-');
      },
    },
    birthDateErrorType() {
      if (!this.birthDate) {
        return 'required';
      }

      const date = new Date(this.birthDate);
      if (isNaN(+date)
          || date.getFullYear() !== +this.form.birthYear
          || date.getMonth() + 1 !== +this.form.birthMonth
          || date.getDate() !== +this.form.birthDay
      ) {
        return 'format';
      }

      if (date < new Date(this.availableDates[0])
          || date > new Date(this.availableDates[1])
      ) {
        return 'format';
      }

      return '';
    },
    isValid() {
      return !this.birthDateErrorType;
    },
  },
  methods: {
    async submit() {
      this.isDirty = true;

      if (!this.isValid) {
        return;
      }

      this.isLoading = true;
      this.$emit('dateSet', this.birthDate);
      this.isLoading = false;
    },
    setInputHandler() {
      const inputDate = document.getElementById('birth-date-input');
      if (!inputDate) return;

      this.inputDate = inputDate;

      const focusListener = function() {
        if (this.getAttribute('type') === 'date') {
          if (this.showPicker) this.showPicker();
        }
      };

      inputDate.addEventListener('click', focusListener);
      this.focusListener = focusListener;
    },
    removeInputHandler() {
      if (this.inputDate && this.focusListener) {
        this.inputDate.removeEventListener('focus', this.focusListener);
        this.inputDate = null;
        this.focusListener = null;
      }
    },
  },
  mounted() {
    this.setInputHandler();
  },
  beforeDestroy() {
    this.removeInputHandler();
  },
};
</script>


<style lang="scss" scoped>
  @import "./two-factor-birthdate";
</style>
