




























import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";

import BaseComponent from "@/components/base-component.vue";
import SearchResults from "@/components/search-results/search-results.vue";
import Loader from "@/components/loader.vue";
import { SearchState } from "@/store/modules/search";
import { EventsState } from "@/store/modules/events";
import { CartState } from "@/store/modules/cart";
import { SettingsState } from "@/store/modules/settings";
import { ProductTypes } from "@/product-types";

@Component({
  name: "EventPhotos",
  components: {
    SearchResults,
    Loader,
  },
})
export default class EventPhotos extends BaseComponent {

  private readonly searchState: SearchState = getModule(SearchState, this.$store);
  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  productTypes: any = ProductTypes;

  eventId: string = "";
  eventName: string = "";
  eventDate: string = "";
  isAllPhotosVisible: boolean = false;
  runnerNum: string = "";
  runnerFrameValues: string = "";
  byFace: string = "";
  
  faceImage: string = "";
  selfieId: string = "";
  personId: string = "";
  
  photos: string = "[]";
  notFound: string = "";

  showResults: boolean = false;

  backPageData: any = null;

  get backPage(): string {
    return this.$store.state.goBack;
  }

  get newPage(): string {
    return this.$store.state.goPage;
  }

  get hasArgs(): boolean {
    return this.$route.params.id !== undefined;
  }

  get photosPerPage(): number {
    if (this.smOnly || this.mdOnly) return 12;
    return 50;
  }

  @Watch("backPage")
  async goBack(page: string): Promise<void> {
    await this.setBackPageData(page);
  }

  @Watch("newPage")
  async goPage(page: string): Promise<void> {
    await this.setBackPageData(page);
  }

  async setBackPageData(page: string): Promise<void> {
    if (!page) return;

    this.backPageData = {
      name: page, 
      params: { 
        eventId: this.eventId, 
        eventName: this.eventName, 
        eventDate: this.eventDate, 
      } 
    };
  }

  async searchByTag(eventId: string): Promise<void> {
    const payloadForToken = {
      eventId,
      payload: {},
    };

    const payload: any = {
      eventId: eventId,
      token: '',
      pagination: { offset: 0, count: this.photosPerPage },
    };
    
    const tokenResult: any = await this.searchState.getToken(payloadForToken);
    if (tokenResult.status !== 200) {
      await this.showNotFoundResults();
      return;
    }

    const token = this.searchState.searchToken ? this.searchState.searchToken.value : '';
    payload.token = token;

    await this.searchState.searchByTag(payload);

    if (this.searchState.searchError) {
      await this.showNotFoundResults();
      return;
    }
    
    if (this.searchState.photos.length > 0) {
      await this.showFoundResults();
      // console.log('= found');
    } else {
      await this.showNotFoundResults();
      // console.log('= not found');
    }
  }

  async showFoundResults(): Promise<void> {
    const event: any = this.eventsState.event;
    if (event === null) {
      this.$store.state.history = [];
      await this.$router.push("/");
      return;
    }

    this.eventId = event.eventId;
    this.eventName = event.title;
    this.eventDate = event.date.slice(0, 10);
    this.isAllPhotosVisible = event.allPhotosVisibility ? event.allPhotosVisibility === 'Visible' : false;
    
    this.faceImage = "";
    this.selfieId = "";
    this.personId = "";
    this.runnerFrameValues = "";
    
    this.photos = JSON.stringify(this.searchState.photos);
    this.notFound = "false";

    this.showResults = true;
  }

  async showNotFoundResults(): Promise<void> {
    const event: any = this.eventsState.event;
    if (event === null) {
      this.$store.state.history = [];
      await this.$router.push("/");
      return;
    }

    this.eventId = event.eventId;
    this.eventName = event.title;
    this.eventDate = event.date.slice(0, 10);
    this.isAllPhotosVisible = event.allPhotosVisibility ? event.allPhotosVisibility === 'Visible' : false;
    this.runnerNum = this.byFace === "true" ? "" : this.$route.params.number;
    this.runnerFrameValues = "";

    this.faceImage = "";
    this.selfieId = "";
    this.personId = "";

    this.photos = JSON.stringify([]);
    this.notFound = "true";

    this.showResults = true;
  }

  async mounted(): Promise<void> {
    this.$store.state.buttonHome = false;
    this.$store.state.buttonBack = true;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = false;
    this.$store.state.goBack = "";

    await this.settingsState.setAppBarHidden(false);
    await this.settingsState.setLoadingRoute(true);

    await this.cartState.loadCartId();

    if (this.cartState.cartId != "") { 
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      }
    }

    this.backToTop();

    if (this.$route.params.id) {
      
      await this.eventsState.getEventByExternalId(this.$route.params.id);
      const event: any = this.eventsState.event;

      if (event === null || event.is2FAEnabled || (event.allPhotosVisibility ? event.allPhotosVisibility !== 'Visible' : false)) {
        await this.settingsState.setLoadingRoute(false);
        await this.$router.push("/");
        return;
      }

      this.byFace = "false";
      if (this.$store.state.history.length == 0) {
        this.$store.state.history = ["home"];
      }
      await this.searchByTag(event.eventId);
      await this.settingsState.setLoadingRoute(false);
      return;

    } else if (!this.hasArgs) {
      await this.settingsState.setLoadingRoute(false);
      await this.$router.push("/");
      return;
    } 

  }
}
