



















































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
const { v4: uuidv4 } = require("uuid");

import BaseComponent from "@/components/base-component.vue";
import SearchFormShort from "@/components/home/search-form-short.vue";
import GiftForm from "@/components/gift-form/gift-form.vue";
import Loader from "@/components/loader.vue";
import NavTabs from "@/components/nav-tabs/nav-tabs.vue";
import { EventsState } from "@/store/modules/events";
import { SettingsState } from "@/store/modules/settings";
import { SearchState } from "@/store/modules/search";
import { CartState } from "@/store/modules/cart";
import { CatalogState } from "@/store/modules/catalog";
import { ProductTypes } from "@/product-types";

@Component({
  name: "Event",
  components: {
    SearchFormShort,
    GiftForm,
    Loader,
    NavTabs,
  },
})
export default class Event extends BaseComponent {

  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);
  private readonly searchState: SearchState = getModule(SearchState, this.$store);
  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly catalogState: CatalogState = getModule(CatalogState, this.$store);

  event_: any = null;
  images_: any[] = [];

  productTypes: any = ProductTypes;

  promocodeProduct: any = null;
  packageOffers: any = null;

  giftAdding: boolean = false;

  loading: boolean = true;

  get backPage(): string {
    return this.$store.state.goBack;
  }

  get theme(): any {
    return this.settingsState.theme;
  }

  get themeDone(): boolean {
    return this.settingsState.themeDone;
  }

  get images(): any[] {
    return this.images_;
  }

  get event(): any {
    return this.event_;
  }

  get hasArgs(): boolean {
    return (this.$route.params.id !== undefined);
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get promocodeCatalogProduct(): any {
    const products: any[] = this.getCatalogProducts(this.productTypes.promocode);
    if (products.length == 0) return null;
    return products[0];
  }

  get inCartItems(): any[] {
    let items: any[] = this.getCartItems(this.productTypes.promocode);
    items = this.getGroupedByEvent(items);
    const found = items.find((i: any) => i.eventId === this.event.eventId);
    if (found) return found.items;
    return [];
  }

  get inCartCount(): number {
    return this.inCartItems.length;
  }

  isTagged(): boolean {
    if (!this.event) return false;
    return this.event.searchingPhotos.byTag === true;
  }

  shuffle(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  
  hasCatalogProducts(type: string): boolean {
    const products: any[] = Object.assign([], this.catalogState.products);
    if (products.length === 0) {
      return false;
    } else {      
      const found: any[] = products.filter((r: any) => r.productType === type);
      return found.length > 0;
    }
  }  

  getCatalogProducts(type: string): any[] {
    const products: any[] = Object.assign([], this.catalogState.products);
    if (products.length === 0) {
      return [];
    } else {      
      const found: any[] = products.filter((r: any) => r.productType === type);
      return found;
    }
  }  

  getCartItems(type: string): any[] {
    const products: any[] = Object.assign([], this.cartState.cartItems);
    if (products.length === 0) {
      return [];
    } else {      
      const found: any[] = products.filter((r: any) => r.product.productType === type);
      return found;
    }
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }

  setPromocodeDiscounts() {
    if (!this.promocodeProduct) return;
    if (this.promocodeProduct.packageOffers && this.promocodeProduct.packageOffers.length > 0) {
      this.packageOffers = this.promocodeProduct.packageOffers;
    }
  }

  @Watch("backPage")
  async goBack(page: string): Promise<void> {
    try {
      await this.$router.push({ name: page });
    } catch(err) {
      if (
        err.name !== 'NavigationDuplicated' &&
        !err.message.includes('Avoided redundant navigation to current location')
      ) {
        console.log(err.name || err.message);
      }
    }
  }

  @Watch("lang")
  async onLangChanged(): Promise<void> {
    window.location.reload();
  }

  async setImages(event: any): Promise<void> {
    const payloadForToken = {
      eventId: event.eventId,
      payload: {},
    };

    const payload: any = {
      eventId: event.eventId,
      token: '',
      pagination: { offset: 0, count: 100 },
    };
    const tokenResult: any = await this.searchState.getToken(payloadForToken);
    if (tokenResult.status !== 200) {
      return;
    }

    const token = this.searchState.searchToken ? this.searchState.searchToken.value : '';
    payload.token = token;
    
    await this.searchState.loadEventImages(payload);

    let images: any[] = Object.assign([], this.searchState.eventImages);
    this.shuffle(images);

    this.images_ = images;
  }

  async onShowAllPhotos(): Promise<void> {
    const event: any = this.eventsState.event;

    await this.$router.push({
      name: "event-photos",
      params: {
        id: event.externalEventId,
      }
    });
  }

  async onGiftAddToCart(count: number): Promise<void> {
    if (this.inCartCount > 0) {
    if (this.inCartCount === count) {
        this.$router.push({ name: 'cart' }).catch();
        return;
      }
      await this.updatePromocodes(count, true);
      return;
    }

    this.giftAdding = true;

    if (!this.cartState.cart || this.cartState.cartId == "") {
      const cartId: string = uuidv4();
      await this.cartState.createCart({ cartId: cartId });
    }
    const payload = { 
      cartId: this.cartState.cartId,
      data: {
        catalogId: this.event.eventId,
        productId: this.promocodeCatalogProduct.productId,
        items: new Array(),
      }
    };
    
    for (let i = 0; i < count; i += 1) {
      const item: any = {
        attributes: [{ key: "PromocodeId", value: uuidv4() }],
        additionalAttributes: [],
      };
      payload.data.items.push(item);
    }

    await this.cartState.addItems(payload);
    if (this.cartState.isCartError) {
      if (this.cartState.responseStatus == 409) {
        this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
      } else {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      }
      this.giftAdding = false;
      return;
    }
    
    await this.cartState.setCartAttributes({ cartId: this.cartState.cartId });
    await this.cartState.getCart({ cartId: this.cartState.cartId });
    this.giftAdding = false;
    this.$router.push({ name: 'cart' }).catch();
  }

  async onGiftCountChanged(count: number): Promise<void> {
    if (this.inCartCount === 0) return;
    if (!count) return;
    await this.updatePromocodes(count);
  }

  async setPromocodeProduct(): Promise<void> {
    if (!this.promocodeCatalogProduct) return;
    await this.catalogState.getProduct({ productId: this.promocodeCatalogProduct.productId });
    this.promocodeProduct = this.catalogState.product || null;
    if (this.promocodeProduct) {
      this.setPromocodeDiscounts();
    }
  }

  async updatePromocodes(count: number, goToCart: boolean = false): Promise<void> {
    if (count < this.inCartCount) {
      await this.onDeleteProductsById(this.inCartItems.slice(0, this.inCartCount - count));
      if (goToCart) this.$router.push({ name: 'cart' }).catch();
    } else if (count > this.inCartCount) {
      await this.addPromocodes(count - this.inCartCount);
      if (goToCart) this.$router.push({ name: 'cart' }).catch();
    }
  }
  
  async addPromocodes(count: number): Promise<void> {
    if (this.giftAdding) return;
    this.giftAdding = true;

    const payload = { 
      cartId: this.cartState.cartId,
      data: {
        catalogId: this.event.eventId,
        productId: this.inCartItems[0].product.productId,
        items: new Array(),
      }
    };

    for (let i = 0; i < count; i += 1) {
      const item: any = {
        attributes: [{ key: "PromocodeId", value: uuidv4() }],
        additionalAttributes: [],
      };
      payload.data.items.push(item);
    }

    await this.cartState.addItems(payload);
    if (this.cartState.isCartError) {
      if (this.cartState.responseStatus == 409) {
        this.displayError(this.$t('searchResults/errorAlreadyAdded').toString());
      } else {
        this.displayError(this.$t('searchResults/errorAddingProduct').toString() + " " + this.cartState.responseStatus);
      }
      this.giftAdding = false;
      return;
    }

    await this.cartState.getCart({ cartId: this.cartState.cartId });
    this.giftAdding = false;
  }

  async onDeleteProductsById(products: any[]): Promise<void> {
    this.giftAdding = true;
    
    const items: string[] = [];
    for (let i = 0; i < products.length; i++) {
      items.push(products[i].shoppingCartItemId);
    }

    await this.cartState.removeItemsById({ cartId: this.cartState.cartId, items: items });
    await this.cartState.getCart({ cartId: this.cartState.cartId });

    this.giftAdding = false;
  }

  async mounted(): Promise<void> {
    this.$store.state.buttonHome = false;
    this.$store.state.buttonBack = true;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = false;
    this.$store.state.goBack = "";

    await this.settingsState.setAppBarHidden(false);
    await this.settingsState.setLoadingRoute(true);
    
    await this.cartState.loadCartId();

    if (this.cartState.cartId != "") { 
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      }
    }

    window.scrollTo(0, 0);

    if (this.$route.params.id) {
      
      await this.eventsState.getEventByExternalId(this.$route.params.id);
      const event: any = { ...this.eventsState.event };

      if (event === null) {
        await this.settingsState.setLoadingRoute(false);
        await this.$router.push("/");
        return;
      }

      if (event.searchingPhotos.byTag === false && event.searchingPhotos.byFace === false) {
        await this.settingsState.setMyPhotosLink("");
        await this.settingsState.setLoadingRoute(false);
        await this.onShowAllPhotos();
        return;
      }

      await this.catalogState.getProducts({ eventId: event.eventId });
      await this.setPromocodeProduct();

      await this.eventsState.getEventById(event.eventId);
      event["is2FAEnabled"] = this.eventsState.event.competitorsListProtected === true;
      event.searchingPhotos = { ...this.eventsState.event.searchingPhotos };

      if (!event.is2FAEnabled && (event.allPhotosVisibility ? event.allPhotosVisibility === 'Visible' : false)) {
        await this.setImages(event);
      }

      await this.settingsState.setMyPhotosLink("");

      this.event_ = event;

      this.$store.state.history = ["home"];

    } else if (!this.hasArgs) {
      await this.settingsState.setLoadingRoute(false);
      await this.$router.push("/");
      return;
    }

    this.loading = false;
    await this.settingsState.setLoadingRoute(false);

    this.settingsState.trackPage("/event");

  }
}
